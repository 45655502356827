import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-top: 136px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-top: 104px;
  }
`;

export const Content = styled.div`
  max-width: 480px;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    gap: 16px;
  }
`;

export const ImageWrapper = styled.div`
  max-width: 50%;
  width: 50%;
  position: relative;
  img {
    object-fit: contain;
    max-width: 100%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    max-width: 100%;
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
  }
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: row;
  gap: 64px;
  width: 100%;
  justify-content: space-between;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex-direction: column;
    gap: 40px;
  }

  > * {
    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      width: 100%;
      max-width: 100%;
    }
  }
`;

export const Title = styled.h1`
  font-size: 48px;
  line-height: 57px;
  font-weight: 700;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 30px;
    line-height: 40px;
  }
`;

export const ButtonLine = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Subtitle = styled.p`
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  align-content: center;
`;

export const UpperLabel = styled.p`
  font-size: 13px;
  line-height: 16px;
  font-weight: 700;
  color: transparent;
  text-transform: uppercase;
  letter-spacing: 2px;
  background-clip: text;
  background-image: linear-gradient(90deg, #f87475 0%, #5993ea 68.1%);
`;

export const ExtraLabel = styled(Subtitle)`
  font-weight: 500;
  max-width: 280px;
  font-size: 12px;
  line-height: 20px;
  color: #747986;
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    max-width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  column-gap: 32px;
  row-gap: 12px;
  button {
    width: fit-content;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    flex-direction: column;
    gap: 12px;
  }
`;
