import Section from '@/components/ui/Section';
import { Counter as UICounter } from '@swordhealth/ui-corporate';
import styled from 'styled-components';
import CountUp from 'react-countup';
import { useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';

const CounterSection = ({ content }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { counterValue, isSymbolLeft, symbol } = content;
  const counterValueNumber = Number(counterValue);
  const isNumeric = !isNaN(counterValueNumber);

  const [ref, inView] = useInView({
    threshold: 0,
    initialInView: false,
  });

  useEffect(() => {
    if (inView && !isVisible) {
      setIsVisible(true);
    }
  }, [inView, isVisible]);

  const renderCounter = useMemo(() => {
    if (!isNumeric) return counterValue; // Render as string if not a number

    const startValue = Math.max(0, counterValueNumber * 0.9);

    return (
      <CountUp
        start={startValue}
        end={counterValueNumber}
        duration={3}
        prefix={isSymbolLeft ? symbol : ''}
        suffix={!isSymbolLeft ? symbol : ''}
      />
    );
  }, [counterValue, counterValueNumber, isSymbolLeft, symbol, isNumeric]);

  return (
    <StyledSection ref={ref}>
      {isVisible && <UICounter {...content} counter={renderCounter} />}
    </StyledSection>
  );
};

export default CounterSection;

const StyledSection = styled(Section)`
  padding-bottom: 0;
`;
