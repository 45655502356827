import styled, { keyframes, css } from 'styled-components';
import { Container, GradientCircle, Paragraph, SectionHeader } from '@swordhealth/ui-corporate';

const fadeIn = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
`;

export const StyledContainer = styled(Container)`
  padding-top: 160px;
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    gap: 16px;
    padding-top: 96px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.min_sm}px) {
    padding-top: 124px;
  }
`;

export const StyledSectionHeader = styled(SectionHeader)`
  gap: 24px;
  opacity: 0;

  ${(props) =>
    props.animate &&
    css`
      animation: ${fadeIn} 2000ms ease-in-out forwards;
    `};

  h1 {
    font-size: 80px;
    font-weight: ${(props) => props.theme.font.weight.regular};
    letter-spacing: -1.5px;
    line-height: 80px;

    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      max-width: 628px;
      font-size: 48px;
      letter-spacing: -1px;
      line-height: 56px;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.min_sm}px) {
      max-width: 312px;
      font-size: 32px;
      letter-spacing: -0.5px;
      line-height: 40px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    gap: 16px;
  }
`;

export const StyledParagraph = styled(Paragraph)`
  color: #747986;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  opacity: 0;

  ${(props) =>
    props.animate &&
    css`
      animation: ${fadeIn} 2000ms ease-in-out forwards;
    `};

  button {
    padding: 17px 32px;
    width: fit-content;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    flex-direction: column;
    gap: 16px;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  padding-top: 32px;
  z-index: 1;
  height: 392px;

  img {
    object-fit: contain;
    max-width: 100%;
    opacity: 0;

    @keyframes fadeInUp {
      from {
        opacity: 0;
        transform: translateY(20%);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }

    ${(props) =>
      props.animate &&
      css`
        animation: fadeInUp 1500ms ease-in-out forwards;
      `};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-top: 24px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.min_sm}px) {
    padding-top: 8px;
    max-width: 100%;
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
  }
`;

export const GradientBG = styled(GradientCircle)`
  position: absolute;
  max-width: 800px;
  max-height: 350px;
  left: 50%;
  transform: translateX(-50%) scaleX(-1);
  z-index: -1;
  opacity: 0.9;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    max-height: 300px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.min_sm}px) {
    max-width: 400px;
    max-height: 140px;
  }
`;
