import { Button, Heading, Paragraph, SectionHeader } from '@swordhealth/ui-corporate';
import { Content, ButtonWrapper, UnitHeader, ImageWrapper, Arrow } from './styles';
import { Container } from '@/components/ui/flexbox';
import { products } from '@/utils/products';
import Image from 'next/image';
import Section from '@/components/ui/Section';
import CampaignButton from './CampaignButton';
import { ACTIONS, trackButtonClicked } from '@/utils/track';

const SolutionConditions = ({ content, campaign }) => {
  const { upperlabel, sectionHeader, conditions } = content;

  const { title, titleSize, titleSeoLevel, description, textLink, buttonGroup, xAlign } =
    sectionHeader;
  if (conditions.data.length < 1) return null;

  const handleConditionClick = (condition, solution) => {
    trackButtonClicked({
      action: ACTIONS.REDIRECT_TO_COVERAGE,
      buttonText: condition?.label,
      buttonLocation: 'Solution Conditions',
      buttonSolution: solution?.attributes?.product?.data?.attributes?.name,
    });
  };

  return (
    <Section>
      <Container>
        <SectionHeader
          title={title}
          label={upperlabel}
          titleSize={titleSize}
          titleSeoLevel={titleSeoLevel}
          description={description}
          textLink={textLink}
          buttonGroup={buttonGroup}
          xAlign={xAlign}
        />
        <Content>
          {conditions?.data?.map((solution) => {
            const { title, product, conditions } = solution.attributes;
            const { logo, productKey } = product.data.attributes;
            if (conditions.length > 0)
              return (
                <>
                  <UnitHeader>
                    <ImageWrapper>
                      <Image
                        fill
                        src={
                          logo
                            ? logo?.data?.attributes?.url
                            : products[productKey.toUpperCase()]?.logo
                        }
                      />
                    </ImageWrapper>
                    <Heading as="h3" size="xl">
                      {title}
                    </Heading>
                  </UnitHeader>
                  <ButtonWrapper>
                    {conditions.map((condition, index) => (
                      <>
                        {campaign && (
                          <CampaignButton index={index} label={condition.label} productKey={productKey} />
                        )}
                        {!campaign && (
                          <Button
                            key={`conditions_${productKey}_${index}`}
                            variant="tertiary"
                            href={`/eligibility-check?solution=${productKey}&hide_get_started=true&program_type=${
                              products[productKey.toUpperCase()]?.param
                            }`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => handleConditionClick(condition, solution)}
                          >
                            <Paragraph size="lg">{condition.label}</Paragraph>
                            <Arrow />
                          </Button>
                        )}
                      </>
                    ))}
                  </ButtonWrapper>
                </>
              );
          })}
        </Content>
      </Container>
    </Section>
  );
};

export default SolutionConditions;
