import CountUp from '@/components/core/PlatformDynamic/CountUp';
import Conditions from '@/components/core/PlatformDynamic/Conditions';
import LogosColumns from '@/components/core/PlatformDynamic/LogosColumns';
import SideImageIcons from '@/components/core/PlatformDynamic/SideImageIcons';
import PlatformTestimonials from '@/components/core/PlatformDynamic/Testimonials';
import ThreeColumnsCards from '@/components/core/PlatformDynamic/ThreeColumnsCards';

import EnrollCTA from 'components/client/EnrollCTA';
import {
  Counter,
  AsFeaturedIn,
  EffectiveTherapyPrograms,
  GetBetter,
  JustTake,
  OurMembers,
  SigningUp,
  BodyParts,
  Testimonials as DynamicTestimonials,
  MemberImpact,
  ClinicalGrade,
  SolutionConditions,
  TestimonialGrid,
  HeroMedia,
  CardGrid,
  Overlay,
} from '@/components/core/Dynamic';

import {
  ExpertCare,
  WhatTreats,
  ResultsYouCanFeel,
  EverythingYouNeed,
  GettingStarted,
  Testimonials,
  Movement,
  GridCards,
  Cta,
  WhatIsSection,
  SideSlider,
  StickyPanel,
} from '@/app/_components/platform';

import { Countdown, HighlightedCTA } from '@/app/_components/shared';
import { Faqs } from '@/app/components/shared';
import { UnevenGridSection } from '@/app/components/platform';
import CampaignHero from '@/app/components/campaign/Hero';
import HeroThematic from '@/app/components/campaign/HeroThematic';
import { VideoSection } from '@/app/components/shared/Video';

export const COMPONENTS = {
  'dpt.expert-care': ExpertCare,
  'dpt.everything': EverythingYouNeed,
  'dpt.getting-started': GettingStarted,
  'dpt.we-treat': WhatTreats,
  'dpt.results': ResultsYouCanFeel,
  'dpt.testimonials': Testimonials,

  'platform.conditions': Conditions,
  'platform.count-up': CountUp,
  'platform.cta': Cta,
  'platform.logos': LogosColumns,
  'platform.results': ResultsYouCanFeel,
  'platform.side-image': SideImageIcons,
  'platform.side-slider': SideSlider,
  'platform.testimonials': PlatformTestimonials,
  'platform.three-cols': ThreeColumnsCards,

  'sections.as-featured-in': AsFeaturedIn,
  'sections.effective-programs': EffectiveTherapyPrograms,
  'sections.get-better': GetBetter,
  'sections.get-started': EnrollCTA,
  'sections.just-take': JustTake,
  'sections.our-members': OurMembers,
  'sections.signing-up': SigningUp,
  'sections.we-can-help': BodyParts,
  'sections.testimonials': DynamicTestimonials,
  'sections.members': MemberImpact,
  'sections.welcome-unit': WhatIsSection,
  'sections.sticky-panel': StickyPanel,
  'sections.clinical-grade': ClinicalGrade,
  'sections.card-grid': CardGrid,
  'generic.counter': Counter,
  'sections.solution-conditions': SolutionConditions,
  'sections.uneven-grid': UnevenGridSection,
  'sections.testimonials-grid': TestimonialGrid,
  'sections.overlay': Overlay,
  'sections.countdown': Countdown,

  'shared.cta': HighlightedCTA,
  'shared.faqs': Faqs,
  'shared.movement': Movement,
  'shared.grid-cards': GridCards,
  'shared.hero-media': HeroMedia,
  'shared.video': VideoSection,

  'campaigns.hero': CampaignHero,
  'campaigns.hero-thematic': HeroThematic,
};
