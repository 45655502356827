import Image from 'next/image';
import { useContext, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import {
  ButtonWrapper,
  ImageWrapper,
  StyledParagraph,
  StyledSectionHeader,
  GradientBG,
  StyledContainer,
} from './styles';
import getImgSrcWorkaround from '@/utils/images';
import EnrollButton from '@/app/components/core/EnrollButton';
import ClientContext from '@/contexts/client.context';
import Section from '@/components/ui/Section';

const HeroThematic = ({ content }) => {
  const { openModal, client } = useContext(ClientContext);
  const [isVisible, setIsVisible] = useState(false);
  const {
    sectionHeader: { title, upperLabel, description },
    buttonLabel,
    disclaimer,
    image,
  } = content;

  const [reference, inView] = useInView({
    initialInView: false,
    threshold: '0.2',
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView && !isVisible) {
      setIsVisible(true);
    }
  }, [inView, isVisible]);

  return (
    <Section colored ref={reference}>
      <StyledContainer xAlign="center">
        <StyledSectionHeader
          title={title}
          label={upperLabel}
          description={description ?? ''}
          xAlign={'center'}
          titleSeoLevel="h1"
          animate={isVisible}
        />

        <ButtonWrapper animate={isVisible}>
          <EnrollButton
            id="header_button"
            isActive={client.isActive}
            buttonLocation="header"
            openModal={() => openModal({ buttonText: buttonLabel, buttonLocation: 'header' })}
            client={client}
            buttonLabel={buttonLabel}
            product={client?.product}
            redirectToApp={client?.redirectToApp}
          />
          {disclaimer && <StyledParagraph>{disclaimer}</StyledParagraph>}
        </ButtonWrapper>

        <ImageWrapper animate={isVisible}>
          <GradientBG />
          <Image
            id="thematic_hero_image"
            src={getImgSrcWorkaround(image?.url ?? image?.data?.attributes?.url)}
            alt={image?.alternativeText ?? image?.data?.attributes?.alternativeText ?? ''}
            objectFit="contain"
            width={624}
            height={392}
          />
        </ImageWrapper>
      </StyledContainer>
    </Section>
  );
};

export default HeroThematic;
