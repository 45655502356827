import getImgSrcWorkaround from '@/utils/images';
import Image from 'next/image';
import {
  CustomCard,
  ImageContainer,
  GradientIcon,
  WhiteIcon,
  CustomBlockquote,
  EllipsisParagraph,
  Quote,
  CustomPlayButton,
  PlayButton,
} from './styles';
import VideoPlayerModal from '@/components/shared/VideoPlayerModal';
import { useMemo, useState } from 'react';
import Link from 'next/link';

const LARGE_QUOTE_SIZE = 363;

const SOLUTIONS = ['thrive', 'bloom', 'move', 'academy', 'on-call'];

const TestimonialImage = ({ url, alternativeText, ...props }) => (
  <Image
    {...props}
    src={getImgSrcWorkaround(url)}
    alt={alternativeText || ''}
    {...(props.width && props.height ? {} : { fill: true, sizes: `${LARGE_QUOTE_SIZE}px` })}
  />
);

const Testimonial = ({ quote, author, byline, image, video, solution }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const hasVideo = Boolean(video?.data?.attributes?.url ?? video?.url);

  const matchedSolution = useMemo(() => {
    if (!solution) return null;
    const words = solution.toLowerCase().split(/\s+/);
    return SOLUTIONS.find((word) => words.includes(word)) || null;
  }, [solution]);

  return (
    <>
      {hasVideo && (
        <VideoPlayerModal
          modalIsOpen={modalIsOpen}
          closeModal={() => setModalIsOpen(false)}
          videoURL={video?.url ?? video?.data?.attributes?.url}
        />
      )}
      <CustomCard hasHoverEffect={!!video} $withImage={!!image && !hasVideo} $withVideo={hasVideo}>
        {!!image && (
          <ImageContainer>
            <TestimonialImage {...image?.data?.attributes} />
          </ImageContainer>
        )}
        {hasVideo && (
          <CustomPlayButton
            onClick={() => setModalIsOpen(true)}
            size="sm"
            variant="primary"
            rounded
          >
            <PlayButton title={`Play Testimonial Video`} />
          </CustomPlayButton>
        )}
        <CustomBlockquote $withImage={!!image && !hasVideo} $withVideo={hasVideo}>
          {!!image || hasVideo ? <WhiteIcon /> : <GradientIcon />}
          <Quote weight="semibold">{quote}</Quote>
          {(author || byline || solution) && (
            <footer>
              {author && (
                <EllipsisParagraph size="sm" weight="semibold">
                  {author}
                </EllipsisParagraph>
              )}
              {solution && (
                <EllipsisParagraph size="sm">
                  {solution.split(' ').map((word, index) =>
                    word.toLowerCase() === matchedSolution ? (
                      <Link
                        key={index}
                        href={`/${matchedSolution}`}
                        style={{ textDecoration: 'underline', color: 'inherit' }}
                      >
                        {word}
                      </Link>
                    ) : (
                      <span key={index}> {word} </span>
                    ),
                  )}
                </EllipsisParagraph>
              )}
              {byline && <EllipsisParagraph size="sm">{byline}</EllipsisParagraph>}
            </footer>
          )}
        </CustomBlockquote>
      </CustomCard>
    </>
  );
};
export default Testimonial;
