import styled from 'styled-components';

export const Grid = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  gap: 80px;
  justify-content: space-between;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    flex-direction: column;
    gap: 64px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    gap: 40px;
  }
`;

export const IconsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: start;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex-direction: column;
    justify-content: center;
  }
`;
