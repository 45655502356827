import { useContext } from 'react';
import Image from 'next/image';
import { useInView } from 'react-intersection-observer';

import { Container, sword } from '@swordhealth/ui-corporate';

import { Inner, Wrapper, LogoWrapper } from './styles';

import EnrollButton from '@/app/components/core/EnrollButton';

import ClientContext from '@/contexts/client.context';

import useBetterMediaQuery from '@/utils/useBetterMediaQuery';

const TopBar = ({ buttonLabel, darkMode = false, color, showEnrollButton = true }) => {
  const { openModal, client } = useContext(ClientContext);
  const isMobile = useBetterMediaQuery(`(max-width: ${sword.breakpoints.maxSm})`);

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: isMobile ? '30px' : '50px',
  });

  return (
    <div ref={ref}>
      <Wrapper $scroll={!inView} $darkMode={darkMode} $isWhite={color === 'white' || !color}>
        <Container>
          <Inner centered={!showEnrollButton}>
            <LogoWrapper>
              {darkMode ? (
                <Image
                  src={'/sword_white.svg'}
                  alt={'Sword Health'}
                  loading="eager"
                  width={88}
                  height={22}
                />
              ) : (
                <Image
                  src={client?.product?.logo ?? '/sword__colored.svg'}
                  alt={client?.product?.key ?? 'Sword Health'}
                  loading="eager"
                  width={116}
                  height={28}
                />
              )}
            </LogoWrapper>

            {showEnrollButton && (
              <EnrollButton
                id="header_button"
                isActive={client.isActive}
                buttonLocation="navigation"
                openModal={() =>
                  openModal({ buttonText: buttonLabel, buttonLocation: 'navigation' })
                }
                client={client}
                buttonLabel={buttonLabel}
                product={client?.product}
                redirectToApp={client?.redirectToApp}
              />
            )}
          </Inner>
        </Container>
      </Wrapper>
    </div>
  );
};

export default TopBar;
