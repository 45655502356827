import { Hero } from '@swordhealth/ui-corporate';
import { MarkdownRichText } from '@/app/_components/shared';

import Media from './Media';

const HeroMedia = ({ content }) => {
  const { media, mediaFit, mediaRatio } = content.media;
  const { description } = content.sectionHeader;

  return (
    <Hero
      {...content}
      sectionHeader={{
        ...content.sectionHeader,
        description: (
          <span className="markdown-space-preserve">
            <MarkdownRichText elements={['strong', 'b', 'i', 'em', 'u']}>
              {description}
            </MarkdownRichText>
          </span>
        ),
      }}
      mediaRatio={mediaRatio || undefined}
      className={'hero-media'}
    >
      <Media {...media?.data?.attributes} objectFit={mediaFit} priority />
    </Hero>
  );
};

export default HeroMedia;
