import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: all 0.3s ease-in-out;
  padding: 20px 0;
  background-color: white;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding: 10px 0;
  }

  ${(props) =>
    props.$scroll &&
    css`
      box-shadow: rgba(233, 220, 211, 0.4) 0 2px 10px 0;
    `};

  ${(props) =>
    !props.$isWhite &&
    css`
      background-color: #f7f4f2 !important;
    `};

  ${(props) =>
    props.$darkMode &&
    css`
      z-index: 100001;
      background-color: transparent;
      box-shadow: unset;
      width: 100%;
    `};
`;

export const Inner = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  & > button {
    height: 40px;
    padding: 8px 23px;
  }

  ${(props) =>
    props.centered &&
    css`
      justify-content: center;
    `};
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
