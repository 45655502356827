import { TestimonialsSlider } from '@/app/_components/shared';
import { SectionWrap } from '@swordhealth/ui-corporate';
import styled from 'styled-components';

export const Section = styled(SectionWrap)`
  padding-top: var(--section-padding-vertical);
  header {
    --ui-text-link-active-color-primary: var(--ui-button-active-background-primary);
    --ui-button-active-color-text: var(--ui-button-active-background-primary);
  }

  * {
    --card-shadow: none !important;
  }
`;
export const GridContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: var(--ui-content-carousel-gap-lg);
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: none;
  }
`;
export const TabletGrid = styled.div`
  display: none;
  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) and (max-width: ${(props) =>
      props.theme.breakpoints.max_md}px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: var(--ui-content-carousel-gap-lg);
    align-items: center;
  }
`;
export const TestimonialColumn = styled.div`
  display: flex;
  heigth: auto;
  flex-direction: column;
  gap: var(--ui-content-carousel-gap-lg);
  &:nth-of-type(2) {
    flex-direction: column-reverse;
  }
`;
export const CustomTestimonialsSlider = styled(TestimonialsSlider)`
  display: none;
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    display: block;
  }
`;

export const Slider = styled.div`
  cursor: grab;
  overflow: hidden;
  margin: 0 -1.5rem;

  .embla__container {
    align-items: center;
    display: flex;
    touch-action: pan-y;
  }

  .embla__slide {
    flex: 0 0 auto;
    width: 280px;
    margin: 0 var(--testimonials-margin-width);
    pointer-events: none;
    position: relative;
    align-items: center;
    display: flex;

    &.slide-active {
      pointer-events: auto;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      --testimonials-margin-width: 6px;
      --testimonial-width: 272px;
      width: 280px;
      height: 480px;
    }
  }
`;
